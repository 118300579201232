import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//bootstrap
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
//css
import "./assets/css/custom.css";
import "./assets/css/icons.min.css";
import "./assets/css/bootstrap.min.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Pagination from "v-pagination-3";
import _ from "lodash";
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";
import Datepicker from "vue3-datepicker";
import { Skeletor } from "vue-skeletor";

// console.log = () => {}

const mixins = {
  methods: {
    getDate: function (date, time) {
      let d =  new Date(date).toLocaleDateString("en-US")
      if (time) {
      return d +" " +new Date(date).toLocaleTimeString("en-US", {hour12: false});
      }
      return d ;

    },
    toastMessage: function (icon, message) {
      this.$swal({
        title: message,
        position: "top-end",
        icon,
        toast: true,
        timer: "3000",
        showConfirmButton: false,
      });
    },
    success: function (message) {
      this.$swal({
        title: message,
        position: "top-end",
        icon: "success",
        toast: true,
        timer: "3000",
        showConfirmButton: false,
      });
    },
    failed: function (message) {
      this.$swal({
        title: message,
        position: "top-end",
        icon: "error",
        toast: true,
        timer: "3000",
        showConfirmButton: false,
      });
    },
  },
};

createApp(App)
  .use(store)
  .use(VueSweetalert2)
  .use(router)
  .use(_)
  .use(Datepicker)
  .component("pagination", Pagination)
  .component(Skeletor.name, Skeletor)
  .component("vue-tree", VueTree)
  .mixin(mixins)
  .mount("#app");
