<template>
    <div>
        <div class="heading_box text-center  mb-3 mb-md-5">
            <h2 class="mb-3 text-uppercase">{{main_heading}} <br/> {{ main_heading1}}</h2>
            <p class="mb-3" >{{paragraph}} <br/>{{paragraph1}} </p>
        </div>
    </div>
</template>
<script>
export default {
    name:'MainheadingComponent',
    props:{
       main_heading:String,
       main_heading1:String,
       paragraph:String,
       paragraph1:String,
    }

}
</script>
<style scoped>
.heading_box h2{
color:var( --yellow);
font-weight: 400;
font-size: 33px;
font-family: 'Conthrax';
}
.heading_box p{
color:var( --white);
font-weight: 500;
font-size: 16px;
font-family: 'Montserrat ' , sans-serif;
}
.about_us .heading_box p,
.feature_box .heading_box p,
.services_box .heading_box p{
    display: none;
}
.roadmap_box .heading_box{
    margin-bottom: 0 !important;

}
@media all and (min-width: 992px) and (max-width: 1199px) {
    .heading_box h2{
        font-size: 35px;
    }
}
@media all and (min-width: 768px) and (max-width: 991px) {
    .heading_box h2{
        font-size: 28px;
    }
    .heading_box p{
        font-size: 15px;
    }
    .support_contact .heading_box p,
    .roadmap_box .heading_roadmap p{
        font-size: 14px;
    }
}
@media all and (min-width: 320px) and (max-width: 767px) {
    .heading_box h2{
        font-size: 22px;
    }
    .heading_box p{
        font-size: 15px;
    }
  
}
</style>