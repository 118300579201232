import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
export default createStore({
  plugins: [createPersistedState()],
  state: {
    opened:0,
    full:false,
    collapsed:false,
    loggedIn:false
  },
  getters: {
    getopened(state){
      return state.opened;
    },
    getfullscreen(state){
      return state.full;
    },
    getcollapsed(state){
      return state.collapsed;
    },
    getloggedIn(state){
      return state.loggedIn;
    }
    
  },
  mutations: {
    setopened(state, payload){
      return state.opened = payload;
    },
    setfullscreen(state, payload){
      return state.full = payload;
    },
    setcollapsed(state,payload){
      return state.collapsed = payload;
    },
    setloggedIn(state,payload){
      return state.loggedIn = payload;
    }
  },
  actions: {
  },
  modules: {
  }
})
