<template>
    <div class="main_footer pb-3">
        <footer class="footer_box ">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div class="mb-lg-3 mb-2">
                            <router-link to="/"> <img src="../assets/images/logo-new1.png" alt="logo" class="img-fluid">
                            </router-link>
                        </div>
                        <div class="pe-2">
                            <p>Everyone Benefits When We
                                Take Care Of Our Appearance,
                                Health, And Well-Being!!</p>
                        </div>
                        <div class="social_icons">
                            <ul class="d-flex list-unstyled align-items-center">
                                <li> <a href="https://www.facebook.com/profile.php?id=100085630628385" target="_blank"><img src="../assets/images/footer/fb.png" alt="social-icon" class="me-lg-3 me-0"></a>
                                </li>
                                <li> <a href="https://www.instagram.com/reel/ClqkL_oJDJL/?igshid=MDJmNzVkMjY=" target="_blank"> <img src="../assets/images/footer/in.png" alt="social-icon" class="me-lg-3 me-0"> </a>  
                                </li>
                                <li> <img src="../assets/images/footer/twitter.png" alt="social-icon"
                                        class="me-lg-3 me-0"> </li>
                                <li> <a href="https://youtu.be/SGZT3qsHx28" target="_blank"><img src="../assets/images/footer/yt.png" alt="social-icon" class="me-lg-3 me-0"> </a>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2 col-xl-3">
                        <div class="footer_box mb-4">
                            <h3 class="text-uppercase">Helpful Navigation</h3>
                            <ul class="list-unstyled fw-light">
                                <li>
                                    <router-link to="/about-us"> About Us</router-link>
                                </li>
                                <li>
                                    <router-link to="/login">Login</router-link>
                                </li>
                                <li>
                                    <router-link to="/signin">Get Started</router-link>
                                </li>
                                <li>
                                    <router-link to="/token"> Tokens</router-link>
                                </li>
                                <li>
                                    <router-link to="/platform"> Platform</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-2 col-xl-3">
                        <div class="footer_box mb-4 ">
                            <h3 class="text-uppercase">Services & Terms</h3>
                            <ul class="list-unstyled">
                                <li>
                                    <router-link to="/crypto-setup">Crypto Setup</router-link>
                                </li>
                                <li>
                                    <router-link to="/updatewallet">Add Wallet </router-link>
                                </li>
                                <li>
                                    <router-link to="/staking-platform">Staking Platform</router-link>
                                </li>
                                <li>
                                    <router-link to="/term-condition">Terms & Conditions</router-link>
                                </li>
                                <li>
                                    <router-link to="/privacy-policy">Privacy Policy</router-link>
                                </li>
                                <li>
                                    <router-link to="/consultants">Free Consultants</router-link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </footer>
        <div class="social-icons pt-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                        <div class="text-center copy-right">
                            <img src="../assets/images/footer/border.png" alt="image" class="img-fluid mb-4">
                            <p> &copy; Copyright <router-link to="/" class="text-decoration-none">@ LBM.io..
                                </router-link> All Rights Reserved </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.main_footer {
    background: var(--black);
    /* background: url(../assets/images/footer/bg.png); */
    padding: 70px 0;
}

.footer_box h3 {
    color: var(--yellow);
    font-size: 17px;
    font-weight: 400;
}

.footer_box p {
    color: var(--white);
}

.footer_box ul li {
    padding: 6px;
}

.footer_box ul li a {
    color: var(--grey);
    text-decoration: none;
    font-weight: 500;
}

.footer_box ul li:hover a {
    color: var(--yellow);
    transform: translate(0, 20px);
}

.copy-right p {
    color: var(--white);
}

.copy-right a {
    color: var(--yellow);
}

/* ================================
            MEDIA QUERY
 ================================ */

@media all and (min-width:320px) and (max-width:576px) {
    .footer_box h3 {
        font-size: 15px;
        font-weight: 500;
    }
}
</style>
