import { createRouter, createWebHistory } from 'vue-router';
import home from "../views/HomeView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: { guestOnly: true }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "activate_id" */ '../views/Dashboard.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/activateid',
    name: 'activateid',
    component: () => import(/* webpackChunkName: "activate_id" */ '../views/active_id.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/reactivateid',
    name: 'reactivateid',
    component: () => import(/* webpackChunkName: "reactivateid" */ '../views/re_active_id.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/updatewallet',
    name: 'updatewallet',
    component: () => import(/* webpackChunkName: "updatewallet" */ '../views/Update_Wallet_Address.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: () => import(/* webpackChunkName: "ChangePassword`" */ '../views/Reset_Password.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/buywallet',
    name: 'buywallet',
    component: () => import(/* webpackChunkName: "buywallet" */ '../views/Buy_Wallet.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/transfertoid',
    name: 'transfertoid',
    component: () => import(/* webpackChunkName: "transfertoid" */ '../views/Transfer_to_id.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/wallettransfer',
    name: 'wallettransfer',
    component: () => import(/* webpackChunkName: "wallettransfer" */ '../views/Wallet_Transfer.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/withdrawl',
    name: 'withdrawl',
    component: () => import(/* webpackChunkName: "withdrawl" */ '../views/Withdrawal.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/directlist',
    name: 'directlist',
    component: () => import(/* webpackChunkName: "directlist" */ '../views/Direct_List.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/downlinelist',
    name: 'downlinelist',
    component: () => import(/* webpackChunkName: "downlinelist" */ '../views/Downline_List.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/levelwiselist',
    name: 'levelwiselist',
    component: () => import(/* webpackChunkName: "levelwiselist" */ '../views/Level_Wise_List.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/treeview',
    name: 'treeview',
    component: () => import(/* webpackChunkName: "treeview" */ '../views/Tree_View.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/ewalletreport',
    name: 'ewalletreport',
    component: () => import(/* webpackChunkName: "ewalletreport" */ '../views/E-Wallet_Report.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/bonuswalletreport',
    name: 'bonuswalletreport',
    component: () => import(/* webpackChunkName: "ewalletreport" */ '../views/BonusReport.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/bonusreport',
    name: 'bonusreport',
    component: () => import(/* webpackChunkName: "bonusreport" */ '../views/Bonus_Report.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/levelincome',
    name: 'levelincome',
    component: () => import(/* webpackChunkName: "levelincome" */ '../views/Level_Income.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/bonusincome',
    name: 'bonusincome',
    component: () => import(/* webpackChunkName: "bonusincome" */ '../views/Bonus_Income.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/bonanza',
    name: 'bonanza',
    component: () => import(/* webpackChunkName: "bonanza" */ '../views/Bonanza.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/rewards',
    name: 'rewards',
    component: () => import(/* webpackChunkName: "rewards" */ '../views/Rewards.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/createticket',
    name: 'createticket',
    component: () => import(/* webpackChunkName: "createticket" */ '../views/Create_Ticket.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/viewticket',
    name: 'viewticket',
    component: () => import(/* webpackChunkName: "viewticket" */ '../views/View_Ticket.vue'),
    meta: { authOnly: true }
  },


  {
    path: '/signin',
    name: 'SigninView',
    component: () => import(/* webpackChunkName: "signin" */ '../views/auth/SigninView.vue'),
    // meta: { guestOnly: true }
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/LoginView.vue'),
    meta: { guestOnly: true }
  },
  {
    path: '/forget',
    name: 'ForgetView',
    component: () => import(/* webpackChunkName: "forget" */ '../views/auth/ForgetView.vue'),
    meta: { guestOnly: true }
  },
  {
    path: '/otp',
    name: 'OTPVerification',
    component: () => import(/* webpackChunkName: "OTPVerification" */ '../views/auth/OTPVerification.vue'),
    meta: { guestOnly: true }
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/auth/ResetPassword.vue'),
    meta: { guestOnly: true }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
function isLoggedIn() {
  return localStorage.getItem('user_token');
}

router.beforeEach((to, from, next) => {



  if (to.matched.some(record => record.meta.authOnly)) {

    if (!isLoggedIn()) {
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    if (isLoggedIn()) {
      next({
        path: '/dashboard',
      })
    } else {

      next()
    }
  } else {
    next();

  }
})
export default router
