<template>
    <div class="exchange-header">
        <nav class="navbar navbar-expand-xl ">
            <div class="container-fluid">
                <router-link class="navbar-brand" to="/">
                    <img src="../assets/images/logo-new1.png" alt="logo" class="img-fluid" style="width:200px;">
                </router-link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #fff;"><path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path></svg>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                        <li class="nav-item">
                            <router-link class="nav-link active" to="/">Home</router-link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/#about">About Us</a>
                        </li>

                        <!-- <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href=""> Features</a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="#">Action</a></li>
                            <li><a class="dropdown-item" href="#">Another action</a></li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                    </li> -->

                        <li class="nav-item">
                            <a class="nav-link" href="/#roadmap">Roadmap</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"  href="#">Whitepaper</a>
                        </li>
                        <!-- <li class="nav-item">
                        <a class="nav-link" href="">Blogs</a>
                    </li> -->
                        <!-- <li class="nav-item">
                        <a class="nav-link" href="">Tokenomics</a>
                    </li> -->
                        <li class="nav-item">
                            <a class="nav-link" href="/#contact">Contact</a>
                        </li>
                        <li class="nav-item" v-if="!loggedIn">
                            <router-link class="nav-link" to="/login">
                                <button class="btn_back text-uppercase" type="button">Login</button>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="!loggedIn">
                            <router-link class="nav-link" to="/signin">
                                <button class="btn_next text-uppercase" type="button">Register</button>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="loggedIn">
                            <router-link class="nav-link" to="/dashboard">
                                <button class="btn_back text-uppercase" type="button">Dashboard</button>
                            </router-link>
                        </li>




                    </ul>

                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import pdf from '@/assets/images/pdf/whitepaper.pdf';
export default {
    name: 'HomeView',
    components: {},
    data() {
        return {
 
            loggedIn:false,
            pdf:pdf,
 
        }
    },
    watch: {
        "$store.getters.getloggedIn": {
            deep: true,
            handler() {
                this.loggedIn = this.$store.getters.getloggedIn;
            },
        },
    },
    mounted() {
        let token = localStorage.getItem('user_token');
        if (token) {
            this.loggedIn = true;
        }
    },
    methods: {}
}
</script>

<style scoped>
.exchange-header {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: var(--black);
}

/* .navbar-nav .nav-item a{
    color: var(--white);
} */

ul.navbar-nav {
    gap: 15px;
}

ul.navbar-nav li.nav-item a {
    color: var(--white);
    font-size: 15px;
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
    color: var(--yellow);
}

/* ================================
            MEDIA QUERY
 ================================ */

@media all and (min-width:1199px) and (max-width:1399px) {
    ul.navbar-nav {
        gap: 6px;
    }
}
</style>
