<template>
    <div class="home">
        <!-- BANNER SECTION  START -->
        <section class="home_banner p-0" id="home">
            <div class="banner_video">
                <!-- BANNER BACKGROUND VIDEO -->
                <video playsinline autoplay muted loop poster="" id="bgvid">
                    <source src="../assets/video/home-banner.mp4" type="video/webm" />
                    <source src="../assets/video/home-banner.mp4" type="video/mp4" />
                </video>

            </div>
            <!-- BANNER CONTENT -->
            <div class="banner_content">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-10 col-md-9  col-lg-8 col-xl-7 col-xxl-7">
                            <div class="banner_haeding">
                                <h1 class="mb-4">
                                    Introducing A <br /> Decentralized
                                    Marketplace <span>Powered by Blockchain</span>
                                </h1>
                                <p class="mb-4">
                                    Transparency, reliability, and decentralization are the
                                    hallmarks of LBM's ecosystem.
                                </p>
                                <div class="banner_button d-flex gap-3">
                                    <router-link to="/signin" v-if="!loggedIn">
                                        <button type="button" class="text-uppercase btn_next">
                                            get started
                                        </button>
                                    </router-link>
                                    <router-link to="/dashboard" v-else>
                                        <button type="button" class="text-uppercase btn_next">
                                            get started
                                        </button>
                                    </router-link>
                                    <router-link to="/">
                                        <button type="button" class="text-uppercase btn_back">
                                            Whitepaper
                                        </button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- BANNER SECTION  END-->

        <!-- ABOUT US SECTION START -->
        <section class="about_us" id="about">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                        <MainheadingComponent main_heading="About Us" />
                    </div>
                </div>
                <!-- headings -->
                <div class="row align-items-center">
                    <div class="col-md-12 col-lg-6 col-xl-6">
                        <div class="video_box text-center">
                            <!-- <video width="620" height="400" autoplay playsinline loop>
                            <source src="../assets/images/home/aboutus/about_video.mp4" type="video/mp4">
                        </video> -->
                            <video playsinline autoplay muted loop poster="" width="620" height="400">
                                <source src="../assets/images/home/aboutus/about_video.mp4" type="video/webm" />
                                <source src="../assets/images/home/aboutus/about_video.mp4" type="video/mp4" />
                            </video>

                        </div>
                    </div>

                    <!-- video section -->
                    <div class="col-md-12 col-lg-6 col-xl-6">
                        <div class="about_info">
                            <h3 class="mb-3">What is LBM?</h3>
                            <p class="mb-3">Being the global community of the decentralized blockchain
                                ecosystem, LBM is a marketing platform that connects smart
                                contracts with blockchain networks around the world.

                            </p>
                            <p class="mb-3">At LBM, we aim to create a unified blockchain ecosystem that
                                integrates companies, employees, and assets, resulting in more
                                efficient, transparent, and reliable business operations.
                                Additionally, our mission is to empower Nano Technology with
                                Blockchain technology.</p>

                        </div>

                    </div>
                    <!-- info section -->
                </div>
            </div>
        </section>
        <!-- ABOUT US SECTION END-->

        <!-- VIDEO SECTION START -->
        <section class="video_content p-0 position-relative">
            <!-- <video autoplay playsinline loop id="my_add">
            <source src="../assets/images/home/aboutus/video.mp4" type="video/mp4">
        </video> -->
            <video playsinline autoplay muted loop poster="" id="my_add">
                <source src="../assets/images/home/aboutus/video.mp4" type="video/webm" />
                <source src="../assets/images/home/aboutus/video.mp4" type="video/mp4" />
            </video>
            <!-- <span v-show="paused"  @click="pause" class="play_btn1 text-white"></span> @playing="updatePaused" @pause="updatePaused" @canplay="updatePaused"
        <span v-show="playing" @click="play"  class="play_btn1 text-white"><img src="../assets/images/home/aboutus/icon.png" alt="text" class="img-fluid"> 
        </span>-->
        </section>
        <!-- VIDEO SECTION END -->

        <!-- FEATURE SECTION START -->
        <section class="feature_box" id="feature">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                        <MainheadingComponent main_heading="features" />
                    </div>
                </div>
                <!-- headings -->
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                        <div class="slider_box">
                            <swiper :autoplay="{ delay: 2500, disableOnInteraction: false, }" :navigation="true"
                                :modules="modules" class="mySwiper" :slides-per-view="3" :loop="true"
                                :space-between="30" @swiper="onSwiper" :spaceBetween="10"
                                :pagination="{ clickable: true, }" @slideChange="onSlideChange" :breakpoints="{
                                    '320': {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    '767': {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                    '1024': {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                }">

                                <swiper-slide v-for="(data, index) in FeatureData" :key="index">
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="feature_box1  text-center p-4 ">
                                                <div class="feature_box_img mb-3">
                                                    <img :src="require(`@/assets/images/home/feature/${data.icon}`)"
                                                        alt="" class="img-fluid" />
                                                </div>
                                                <div class="feature_box_content">
                                                    <h3 class="mb-2 text-uppercase">{{ data.head }}</h3>
                                                    <p class="mb-0">{{ data.para }}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <!-- FEATURE SECTION END -->

        <!-- SERVICES SECTION START -->
        <section class="services_box" id="service">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                        <MainheadingComponent main_heading="services" />
                    </div>
                </div>
                <!-- headings -->
                <div class="row">
                    <!-- <div class="col-md-1">
               <div class="side_text text-nowrap"><p class="mb-0">What MYV Offer</p></div>
                </div> -->
                    <div class="col-md-12 col-lg-12 col-xl-12">
                        <div class="slider_box">
                            <swiper :autoplay="{ delay: 2500, disableOnInteraction: false, }" :navigation="true"
                                :modules="modules" class="mySwiper" :slides-per-view="3" :loop="true"
                                :space-between="30" @swiper="onSwiper" :spaceBetween="10"
                                :pagination="{ clickable: true, }" @slideChange="onSlideChange" :breakpoints="{
                                    '320': {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    '767': {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                    '992': {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                }">

                                <swiper-slide v-for="(data, index) in ServiceData" :key="index">
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="feature_box  text-center p-4 glass_effect">
                                                <div class="feature_box_img mb-3">
                                                    <img :src="require(`@/assets/images/home/service/${data.icon}`)"
                                                        alt="" class="img-fluid" />
                                                </div>
                                                <div class="feature_box_content">
                                                    <h3 class="mb-2 text-capitalize">{{ data.head }}</h3>
                                                    <p class="mb-0">{{ data.para }}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- SERVICES SECTION END -->
        <!-- E- COMMERCE SECTION START -->
        <section class="about_us" id="about">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                        <MainheadingComponent main_heading="E-commerce" />
                    </div>
                </div>
                <!-- headings -->
                <div class="row align-items-center">
                    <!-- video section -->
                    <div class="col-md-12 col-lg-6 col-xl-6">
                        <div class="about_info">
                            <!-- <h3 class="mb-3">What is LBM?</h3> -->
                            <p class="mb-3">With LBM, it is now possible to conduct transactions in a simple, fast,
                                and secure manner. The LBM eCommerce platform allows you to trade groceries and other
                                products. Now you can simply integrate LBM into your day-to-day needs.
                            </p>

                        </div>

                    </div>
                    <div class="col-md-12 col-lg-6 col-xl-6">
                        <div class="video_box text-center">
                            <video playsinline autoplay muted loop poster="" width="620" height="400">
                                <source src="../assets/video/ecommerce.mp4" type="video/webm" />
                                <source src="../assets/video/ecommerce.mp4" type="video/mp4" />
                            </video>

                        </div>
                    </div>
                    <!-- info section -->
                </div>
            </div>
        </section>
        <!-- E- COMMERCE SECTION END-->
        <!-- advantage section  START -->

        <section class="advantage" id="advantage">
            <div class="container">
                <MainheadingComponent main_heading="ADVANTAGES" />
                <div class="row">
                    <div class="col-lg-6">
                        <div class="advantage-img">
                            <img src="@/assets/images/advantage.png" class="img-fluid" alt="img">
                        </div>
                    </div>
                    <div class="col-lg-6 align-self-center">
                        <div class="advantage-box">
                            <p class="mb-3 py-4 px-5">Algorithms that are pre-programmed</p>
                            <p class="mb-3 py-4 px-5 left-info">Assurance and safety without compromise</p>
                            <p class="mb-3 py-4 px-5 ">Interface designed with ease of use in mind</p>
                            <p class="mb-3 py-4 px-5 left-info">Transferring money to your wallet instantly</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- advantage  END-->
        <section class="choose-section" id="choose">
            <div class="choose_us_video">
                <video playsinline autoplay muted loop poster="" width="620" height="400">
                    <source src="../assets/video/exchange.mp4" type="video/webm" />
                    <source src="../assets/video/exchange.mp4" type="video/mp4" />
                </video>
            </div>
            <div class="choose_us_content">
                <div class="container">
                    <MainheadingComponent main_heading="Why choose us?"
                        paragraph="Taking growth to the next level is possible with LBM!" />
                    <div class="row">
                        <div class="col-md-6 col-xl-4 col-xxl-4" v-for="(choose, index) in ChooseData" :key="index">
                            <div class="choose-card p-3 mb-4">
                                <div class="choose-icon text-center mb-3">
                                    <!-- <img src="@/assets/images/choose-icon4.png" class="img-fluid " alt="img"> -->
                                    <img :src="require(`@/assets/images/${choose.icon}`)" alt="icon" class="img-fluid"
                                        style="width:70px;">
                                </div>
                                <div class="choose-info">
                                    <h2 class="text-capitalize mb-3 ">{{ choose.head }}</h2>
                                    <p class="text-capitalize mb-3 ">{{ choose.para1 }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- choose section -->

        <!-- section our-bot start -->
        <section class="our-bot">
            <div class="container">
                <div class="row align-items-center justify-content-center ">
                    <div class="col-12 mb-4 ">
                        <mainheading-component main_heading="Our bot trading service"
                            main_heading1="is fully automated!" paragraph="Every decision we make is driven by a user experience mindset. Providing the most immersive 
" paragraph1="
experience for our users is one of our top priorities." />
                    </div>
                    <div class="col-md-6 order-2 order-md-0 mt-md-4">
                        <div class="our-bot-text">
                            <h3 class="mb-4 text-uppercase ">The Future of Trading is Automated!</h3>
                            <p class="mb-5">Using LBM, clients can trade without manual intervention even if they
                                don't have experience or don't have the time.</p>
                            <div class="our-bot-text-inner row align-items-center justify-content-center">

                                <p class="col-12 col-sm-6 text-nowrap"><img class="me-3"
                                        src="../assets/images/home/advantage/icons/circle.svg" alt="circle">Live
                                    Monitoring</p>
                                <p class="col-12 col-sm-6 text-nowrap"><img class="me-3"
                                        src="../assets/images/home/advantage/icons/circle.svg" alt="circle">Market
                                    Analysis</p>
                                <p class="col-12 col-sm-6 text-nowrap"><img class="me-3"
                                        src="../assets/images/home/advantage/icons/circle.svg" alt="circle">High Skilled
                                    Bot</p>
                                <p class="col-12 col-sm-6 text-nowrap"><img class="me-3"
                                        src="../assets/images/home/advantage/icons/circle.svg" alt="circle">24/7 Trade
                                    Ability</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="our-bot-img mb-5 mb-md-0">
                            <img class="img-fluid" src="../assets/images/home/advantage/bot.png" alt="bot">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- section our-bot end -->
        <!-- section  wallet start -->
        <section class="wallet">
            <div class="container">
                <div class=" wallet-row row justify-content-center align-items-center text-center py-5 px-4">
                    <div class="col-12">
                        <MainheadingComponent main_heading="LBM Web 3.0 Wallet"
                            paragraph="Bringing the Web3 World to you!" />
                    </div>
                    <div class="col-12">
                        <div class="wallet-heading-down mb-5 mt-5 text-start">
                            <p class="mb-3 ">A central location for sending, receiving, and storing cryptos and NFTs</p>
                            <p class="mb-3">In seconds, you can create your decentralized Web3 account</p>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="all-in-one mb-5">
                            <h4 class="text-uppercase text-decoration-underline mb-4"> An all-in-one solution for
                                managing digital assets</h4>
                            <p>Having full control over your crypto and NFTs has never been easier than with LBM
                                Wallet. Your digital assets
                                can be sent, received, and stored in seconds with LBM Wallet.</p>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="wallet-box  mt-5 mb-5">
                            <div class="row py-1 px-2">
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="wallet-box-inner my-3 my-md-0">
                                        <h4>Assets Aggregation</h4>
                                        <p>Multiple chain token assets
                                            displayed together</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="wallet-box-inner line-none my-3 my-md-0">
                                        <h4>Easy Storage of NFTs</h4>
                                        <p>Assets stored in NFT can be
                                            easily managed</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="wallet-box-inner my-3 my-md-0">
                                        <h4>Decentralized Account</h4>
                                        <p>Set up your NFT avatars and
                                            create decentralized accounts</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="wallet-box-inner line-none-2 my-3 my-md-0">
                                        <h4>Decentralized Application</h4>
                                        <p>Any decentralized application
                                            can be explored</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="wallet-journey mt-5">
                            <div class="row justify-content-center align-items-center">
                                <div class="col-12 mb-5">
                                    <h4 class="crypto-journey text-uppercase text-decoration-underline"> Start Your
                                        Crypto Journey</h4>
                                </div>
                                <div class="col-md-6 mb-5 mb-md-0">
                                    <div class="wallet-journey-img">
                                        <video autoplay loop muted preload="none">
                                            <source src="../assets/video/wallet-video.mp4" type="video/mp4">
                                            <source src="../assets/video/wallet-video.mp4" type="video/ogg">
                                        </video>
                                        <!-- <img class="img-fluid" src="@/assets/img/home/icons/play-btn.png" alt="play-btn"> -->
                                    </div>
                                </div>
                                <div class="col-md-6 align-self-center">
                                    <div class="wallet-journey-text d-flex justify-content-center">
                                        <div class="circle-line">
                                            <img class="img-fluid"
                                                src="../assets/images/home/advantage/layers/circle-line.png"
                                                alt="circle-line">
                                        </div>
                                        <div
                                            class="text-nowrap circle-line-text d-flex flex-column justify-content-between align-items-start">
                                            <div class="circle-line-text-box">Download LBM Wallet</div>
                                            <div class="circle-line-text-box">Create Account</div>
                                            <div class="circle-line-text-box">Send, Receive and Earn Tokens</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- section wallet end -->

        <!-- E- COMMERCE SECTION START -->
        <section class="about_us" id="about">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                        <MainheadingComponent main_heading="Metaverse" />
                    </div>
                </div>
                <!-- headings -->
                <div class="row align-items-center">
                    <!-- video section -->
                    <div class="col-md-12 col-lg-6 col-xl-6">
                        <div class="about_info">
                            <h3 class="mb-3">LBM Metaverse (The Digital world)</h3>
                            <p class="mb-3">
                                LBM is compatible with the metaverse. There are many things to purchase within the 3D
                                universe, including avatar clothing, real estate, and more with the LBM Metaverse.
                            </p>

                        </div>

                    </div>
                    <div class="col-md-12 col-lg-6 col-xl-6">
                        <div class="video_box text-center">
                            <video playsinline autoplay muted loop poster="" width="620" height="400">
                                <source src="../assets/video/metaverse.mp4" type="video/webm" />
                                <source src="../assets/video/metaverse.mp4" type="video/mp4" />
                            </video>

                        </div>
                    </div>
                    <!-- info section -->
                </div>
            </div>
        </section>
        <!-- E- COMMERCE SECTION END-->
        <!-- section download start -->
        <section class="download">
            <div class="container">
                <div class="row align-items-center justify-content-center ">
                    <div class="col-12">
                        <MainheadingComponent main_heading="Download App" />
                    </div>
                    <div class="col-md-6">
                        <div class="download-img mb-5 mb-md-0">
                            <img class="img-fluid" src="../assets/images/home/advantage/mobile.webp" alt="mobile">

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="download-text">

                            <h3 class="mb-4 ">Stake , Buy and Sell</h3>
                            <p class="mb-4">Get the secured, reliable, and capabilities of the best-in-class regulated
                                LBM App, directly on your smartphone.</p>
                            <button class="btn p-0 me-2 download-btn mb-3"><img class="img-fluid"
                                    src="../assets/images/home/advantage/icons/apple.png" alt="apple"></button>
                            <button class="btn p-0 me-2 download-btn mb-3"><img class="img-fluid"
                                    src="../assets/images/home/advantage/icons/google.png" alt="google"></button>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="roadmap_box position-relative" id="roadmap">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                        <div class="heading_roadmap text-center mb-4">
                            <MainheadingComponent main_heading="Roadmap" paragraph="Our Cryptocurrency Roadmap" />
                            <p class="pt-1">Acknowledging the fact that beauty and health is a process, MYV Token <br />
                                has set a timeline for its developments and priorities.</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-7 col-xl-8"></div>
                    <div class="col-md-6 col-lg-5 col-xl-4">
                        <div class="roadmap_height w-75 m-5">
                            <div class="image_text d-flex align-items-center mb-3">
                                <div><img src="../assets/images/home/roadmap/img1.png" alt="text"
                                        class="img-fluid me-3"></div>
                                <div>Apr , 2022</div>
                            </div>
                            <!-- image -->
                            <div class="content_roadmap">
                                <h3>LBM Coin Creation Starts</h3>
                                <p> Development of health & beauty based crypto asset MYV Token starts in April 2022</p>
                            </div>
                            <!-- head -->
                        </div>
                    </div>
                    <!-- col end -->
                    <div class="col-md-6 col-lg-5 col-xl-4">
                        <div class="roadmap_height rodmap_july w-75 mb-5 me-5">
                            <div class="image_text d-flex align-items-center mb-3 ">
                                <div><img src="../assets/images/home/roadmap/img2.png" alt="text"
                                        class="img-fluid me-3"></div>
                                <div>Jul, 2022</div>
                            </div>
                            <!-- image -->
                            <div class="content_roadmap">
                                <h3>LISTING ON LBM EXCHANGEs</h3>
                                <p> LBM Coin will be listed on various exchanges like UNITED EXCHANGE, BINANCE,
                                    VINDAX,BANK CEX, Also on CMC, COINGECKO, etc.</p>
                            </div>
                            <!-- head -->
                        </div>
                    </div>
                    <!-- col end -->
                    <div class="col-md-6 col-lg-7 col-xl-8"></div>
                    <!-- col end -->
                    <div class="col-md-6 col-lg-6 col-xl-6"></div>
                    <!-- col end -->
                    <div class="col-md-6 col-lg-6 col-xl-6">
                        <div class="roadmap_height w-75 m-auto mb-5 mb-md-0 ps-5 roadmap_public">
                            <div class="image_text d-flex align-items-center mb-3">
                                <div><img src="../assets/images/home/roadmap/img3.png" alt="text"
                                        class="img-fluid me-3"></div>
                                <div>Aug , 2022</div>
                            </div>
                            <!-- image -->
                            <div class="content_roadmap">
                                <h3>Public Sale Starts</h3>
                                <p> Public Sale Starts/Pre Registration (Soft Cap) and started the process</p>
                            </div>
                            <!-- head -->
                        </div>
                    </div>
                    <!-- col end -->
                    <div class="col-md-6 col-lg-5 col-xl-4">
                        <div class="roadmap_height w-75 ps-5 m-auto mb-5 left-transform">
                            <div class="image_text  d-flex align-items-center mb-3">
                                <div><img src="../assets/images/home/roadmap/img4.png" alt="text"
                                        class="img-fluid me-3"></div>
                                <div>Qtr. 4 /2022</div>
                            </div>
                            <!-- image -->
                            <div class="content_roadmap ">
                                <h3>Quarter 4 / 2022</h3>
                                <p>WEB-3.0 Decentralized Wallet Own Crypto Exchange, Fully Functiona</p>
                            </div>
                            <!-- head -->
                        </div>
                    </div>
                    <!-- col end -->
                    <div class="col-md-6 col-lg-7 col-xl-8">
                    </div>
                    <!-- col end -->
                    <div class="col-md-6 col-lg-5 col-xl-4">
                        <div class="roadmap_height w-75 my-5 text-transform1">
                            <div class="image_text d-flex align-items-center mb-3">
                                <div><img src="../assets/images/home/roadmap/img5.png" alt="text"
                                        class="img-fluid me-3"></div>
                                <div>Qtr. 1 /2023</div>
                            </div>
                            <!-- image -->
                            <div class="content_roadmap">
                                <h3>Quarter 1 / 2023</h3>
                                <p>Coin Creater & Launchpad</p>
                            </div>
                            <!-- head -->
                        </div>
                    </div>
                    <!-- col end -->
                    <div class="col-md-6 col-lg-7 col-xl-8">
                    </div>

                    <!-- col end -->
                    <div class="col-md-6 col-lg-7 col-xl-8">
                    </div>
                    <!-- col end -->
                    <div class="col-md-6 col-lg-5 col-xl-4">
                        <div class="roadmap_height w-75 ps-5 m-auto mb-5">
                            <div class="image_text d-flex align-items-center mb-3">
                                <div><img src="../assets/images/home/roadmap/img6.png" alt="text"
                                        class="img-fluid me-3"></div>
                                <div>Qtr 2/3 2023</div>
                            </div>
                            <!-- image -->
                            <div class="content_roadmap">
                                <h3>(Quarter 2/3) 2023</h3>
                                <p>E-commerce</p>
                            </div>
                            <!-- head -->
                        </div>
                    </div>
                    <!-- col end -->
                </div>
            </div>
        </section>

        <!-- section download end -->

        <section class="support_contact" id="contact">
            <div class="container">

                <div class="row support_contactbg  justify-content-md-between">
                    <div class="col-md-6 col-lg-6">
                        <div class="support ">

                            <div
                                class=" d-flex justify-content-start align-items-start px-3 py-4 p-md-4 p-lg-5 pb-lg-4">
                                <span class="supporticon d-flex justify-content-center align-items-center"> <img
                                        src="@/assets/images/icon/bx-comment.svg"></span>
                                <p class=" ms-2">support@LBM.io</p>
                            </div>

                            <div class="mb-4 d-flex justify-content-start align-items-start px-3 px-md-4 px-lg-5">
                                <span class="supporticon d-flex justify-content-center align-items-center"> <img
                                        src="@/assets/images/icon/bx-map.svg"></span>
                                <h1 class=" ms-2">LBM CRYPTECH LTD., 160, City Road, <br> London, UK ECIV2NX</h1>
                            </div>

                            <div class="d-flex justify-content-start px-2 pb-3 ">
                                <div class="mapouter  ">
                                    <div class="gmap_canvas">
                                        <iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no"
                                            marginheight="0" marginwidth="0"
                                            src="https://maps.google.com/maps?width=929&amp;height=400&amp;hl=en&amp;q=University of Oxford&amp;t=&amp;z=9&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">

                                        </iframe>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="col-md-6 col-lg-6">
                        <div class="contact_us mt-md-0 mt-5">
                            <MainheadingComponent main_heading="contact us"
                                paragraph="If you have any query and suggestions contact us by"
                                paragraph1="sending your message." />
                            <form class="mt-4">
                                <div class="mb-4">
                                    <input type="text" class="form-control" id="exampleFormControlInput1"
                                        placeholder="Subject">
                                </div>

                                <div class="mb-4">
                                    <input type="text" class="form-control" id="exampleFormControlInput1"
                                        placeholder="Your Name">
                                </div>

                                <div class="mb-4">
                                    <input type="email" class="form-control" id="exampleFormControlInput1"
                                        placeholder="Your Email Here">
                                </div>

                                <div class="mb-4">
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                                        placeholder="Drop message"></textarea>
                                </div>

                                <div>
                                    <a type="button" href="#" class="btnsend">SEND NOW</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--support_contact-->
        <section class="subscribe_now">
            <div class="container">
                <div class="row px-4 subscribe_nowbg justify-content-md-between align-items-center ">
                    <div class="col-md-6 col-lg-7 col-xl-6">

                        <div class="px-md-4 px-xl-2   px-3 py-md-1 py-2">
                            <h1 class="mb-0">Subscribe Now</h1>

                            <p class="mb-0 mt-3">Subscribe to us for upcoming updates and rewards for LBM
                                <br>community.
                            </p>
                        </div>
                        <div class="px-md-4 px-xl-2 mt-md-0 mt-2 py-md-3 py-lg-3 px-3 py-2">
                            <form class="d-flex subscribe_form">
                                <div>
                                    <input type="email" class="form-control shadow-none" id="exampleFormControlInput1" aria-describedby="emailHelp"
                                        placeholder="Enter Email">
                                </div>
                                <div>
                                    <a type="button" href="#" class="btnsend ms-4">SEND NOW</a>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-9 col-md-5 col-lg-5 col-xl-5">
                        <div class="mt-md-0 mt-3 text-center ">
                            <img src="@/assets/images/man-playing.png" class="img-fluid manimage">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {
    Swiper,
    SwiperSlide
} from "swiper/vue";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
// import "swiper/css/pagination";
import {
    Autoplay,
    Navigation,
    Pagination
} from "swiper";
import MainheadingComponent from '@/components/home/MainHeadingComponent'
export default {
    name: "HomeView",
    components: {
        MainheadingComponent,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            videoElement: null,

            ChooseData: [{
                icon: 'choose-icon1.png',
                head: 'the entire process is automated',
                para1: 'due to a fully verified smart contract, it runs autonomously and cannot be hacked.',
            },
            {
                icon: 'choose-icon2.png',
                head: 'The highest level of transparency',
                para1: 'There is a blockchain for verifying all transactions view the transactions of all members, which means you  can see exactly what is transpiring.',
            },
            {
                icon: 'choose-icon3.png',
                head: 'Speed',
                para1: 'Software code is used to create these contracts,  which are stored on the internet. Due to this,  transactions are executed very quickly.',
            },
            {
                icon: 'choose-icon4.png',
                head: 'You have nothing to lose',
                para1: 'Blockchain, one of the most secure and popular  networks plus popular cryptocurrencies, hosts an  open-source smart contract.',
            },

            {
                icon: 'choose-icon5.png',
                head: 'You can live your life risk-free!',
                para1: 'As part of LBM, a self-executing smart contract was deployed on the LBM Blockchain to allow for  the execution of smart contracts.',
            },
            {
                icon: 'choose-icon6.png',
                head: 'A term of indefinite duration',
                para1: 'Regardless of when the blockchain is created,  smart contracts will exist forever,even their  creators cannot change their terms.',
            },
            ],
            ServiceData: [{
                icon: 'img1.png',
                head: 'Smart Trading Modules',
                para: 'Smart Trading Module brings a 100% end-to-end automated trading solution for users to automate their trading ideas with a simple plug-and-play module',

            },
            {
                icon: 'img2.png',
                head: 'Exchange Management',
                para: 'The Exchange Management is built on Powerful Blockchain technology and provides a powerful command-line interface that enables the automation of Exchange administration.',

            },
            {
                icon: 'img3.png',
                head: 'Instant Notification',
                para: 'SendS real-time push notifications when you are looking for instant responses',

            }
            ],
            FeatureData: [{
                icon: 'img1.png',
                head: 'Metaverse',
                para: 'Yes! You can use LBM in the metaverse. Everything from avatar clothing to real estate can be purchased within the 3D universe.',

            },
            {
                icon: 'img2.png',
                head: 'Ecommerce',
                para: 'Simple, fast, and secure transactions are made possible. LBM eCommerce allows you to trade for groceries and other items.',

            },
            {
                icon: 'img3.png',
                head: 'LBM Arbitrage System',
                para: 'LBM arbitrage system is a great option if you are looking for low-risk, high-frequency trades.',

            }
            ],
            loggedIn: false,

        }
    },
    setup() {
        return {
            modules: [Autoplay, Navigation, Pagination],
        };
    },
    watch: {
        "$store.getters.getloggedIn": {
            deep: true,
            handler() {
                this.loggedIn = this.$store.getters.getloggedIn;
            },
        },
    },
    mounted() {
        let token = localStorage.getItem('user_token');
        if (token) {
            this.loggedIn = true;
        }
    },
};
</script>

<style scoped>
.home {
    background: var(--black);
}

section {
    padding: 70px 0;
}

/* home banner section Css Start  */
.banner_video video,
.choose-section video {
    object-fit: cover;
    width: 100%;
    height: 93vh;
}

.home_banner {
    height: 93vh;
    position: relative;
}

.banner_content {
    position: absolute;
    top: 0;
    left: 0px;
    right: 0;
    width: 100%;
    max-width: 1500px;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner_haeding h1 {
    font-size: 36px;
    font-family: 'Conthrax';
    font-weight: 400;
    line-height: 54px;
    color: var(--white);
}

.banner_haeding h1 span {
    color: var(--yellow);
}

.banner_haeding p {
    color: var(--white);
    font-size: 20px;
}

/* home banner section Css  End */
/* advantage css start  */
.advantage {
    background-color: var(--black);
    padding: 70px 0px;
    position: relative;
    z-index: 0;
}

/* 
.advantage::after {
    position: absolute;
    content: '';
    background-image: url('../assets/images/layer.png');
    top: 0;
    left: -343px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
} */

.advantage-box p:hover {
    background-color: var(--light-yellow);
    transition: all 0.1s ease-in-out;
}

.advantage-box p {
    color: var(--white);
    font-size: 15px;
    background-color: var(--black-2);
    transition: all 0.1s ease-in-out;
}

.left-info {
    transform: translate(50px, 0px);
}

/**************choose-section start*******/
/* .choose-section {
    background-image: url('../assets/images/choose-banner.png');
    background-size: cover;
    min-height: 80vh;
    background-color: var(--black);
    background-position: center;
    background-repeat: no-repeat;
    padding: 60px 0px;
} */
.choose-section {
    position: relative;
}

.choose_us_content {
    position: absolute;
    top: 70px;
    left: 0px;
    right: 0;
    width: 100%;
    /* max-width: 1500px; */
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.choose-card {
    box-shadow: rgb(18 17 17 / 30%) 1px 1px 8px 12px, rgb(119 125 131 / 15%) 0px 1px 3px 1px;
    background-color: var(--black);
    min-height: 255px;
    position: relative;
    z-index: 99;
    background-color: #202020;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    opacity: 0.9;
}

.choose-card:after {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    border-bottom: 3px solid var(--yellow);
    border-right: 3px solid var(--yellow);
    bottom: 0;
    right: 0;
    border-image: linear-gradient(#D3AC35, #D3AC35) 30;

}

/* .choose-icon img {
    width: 84px;
    height: 84px;
} */

.choose-info h2 {
    color: var(--yellow);
    font-size: 17px;
    text-align: center;
}

.choose-info p {
    color: var(--white);
    font-size: 14px;
    text-align: center;
}

video {
    max-width: 100%;
    height: auto;
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: unset;
}

/* our-bot style start */
.our-bot {
    background: var(--black);
    color: var(--white);
    position: relative;
    z-index: 0;
}

.our-bot p,
.wallet p {
    font-size: 16px;
}

.wallet-box-inner p {
    font-size: 14px;
}

.our-bot-text h3,
.download-text h3 {
    color: var(--yellow);
}

.download-text h3 {
    font-size: 27px;
}

.download-text p {
    font-size: 16px;
}

.download-btn:hover img {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
}

.download-btn img {
    transition: all 0.3s ease-in-out;
}

.our-bot::before {
    position: absolute;
    content: '';
    width: 34%;
    height: 100%;
    top: 0;
    left: 20%;
    background: url(../assets/images/home/advantage/layers/curve-line.png) no-repeat center center/cover;

    z-index: -1;
}

/* our-bot style end */

/* download style start */
.download {
    background: var(--l-black);
    color: var(--white);
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
}

.download-btn {
    width: 150px;

}

/* download style end */

/* wallet style end */
.wallet {
    background: var(--black);
    color: #fff;

}

.wallet-row {
    position: relative;
    z-index: 0;
    border-radius: 10px;

    background: linear-gradient(180deg, rgba(211, 172, 53, 1) -20%, rgba(0, 0, 0, 0) 42%);
}

.wallet-row::before {
    position: absolute;
    content: '';
    width: 99.3%;
    ;
    height: 100%;
    top: 6px;
    z-index: -1;
    border-radius: 10px;
    background: linear-gradient(180deg, #202020 36%, rgba(0, 0, 0, 0) 51%);

}

.circle-line img {
    width: 28%;
}

.all-in-one h4 {
    color: var(--yellow);
}

.wallet-box-inner h4 {
    color: var(--yellow);
    font-size: 17px;
}

.crypto-journey {
    color: var(--yellow);
}

.wallet-journey {
    position: relative;
}

.wallet-journey-img {
    position: relative;

}

.wallet-journey-img video {
    height: 500px;
}

.wallet-journey-img img {
    position: absolute;
    top: 43%;
    width: 15%;
    left: 42%;
}

.wallet-journey::before {
    position: absolute;
    content: '';
    background: url('../assets/images/home/advantage/layers/curve-line2.png') no-repeat center center/cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0%;
    z-index: -1;
}

.wallet-box-inner {
    position: relative;
}

.wallet-box-inner::before {

    position: absolute;
    content: '';

    background: url('@/assets/images/home/service/Rectangle.png') no-repeat center center/cover;
    width: 1px;
    height: 100px;
    top: 8%;
    right: -5%;
    z-index: -1;
}

.line-none-2::before {
    content: unset;
}

/*  wallet style start */

/* About us css start */
.about_us .about_info h3 {
    color: var(--white);
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat ', sans-serif;
}

.about_us .about_info p {
    color: var(--grey);
    font-weight: 500;
    font-size: 16px;
    font-family: 'Montserrat ', sans-serif;
}

/* .video_content span{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,50%);
    cursor: pointer;
} */
#my_add {
    min-width: 60%;
    min-height: 100%;
}

/*  feature  */
.feature_box1 {
    min-height: 330px;
}

.feature_box1:hover {
    background-color: var(--dark-grey);
    border-radius: 5px;
    cursor: pointer;
}

.feature_box_content h3 {
    color: var(--yellow);
    font-size: 19px;
    font-weight: 500;
}

.feature_box_content p {
    color: var(--white);
    font-size: 15px;
    font-family: 'Montserrat ', sans-serif;
}

.services_box {
    background-image: url(../assets/images/home/service/bg-image1.png);
    background-repeat: no-repeat;
    background-position: center;
    min-height: 500px;
    background-size: cover;
}

.services_box .side_text {
    transform: rotate(268deg);
    color: var(--white);

}

.services_box .side_text p {
    font-size: 35px;
    font-weight: 500;
    font-family: 'Montserrat ', sans-serif;
}

/* service css  */
.glass_effect {
    background-color: var(--dark-grey);
    border: 1px solid var(--yellow);
    border-radius: 5px;
    min-height: 305px;
    cursor: pointer;
}

/* p */
.support_contactbg {

    background: var(--contact-bg);
    border-radius: 20px;

    color: var(--white);
    padding: 20px 0px;
    margin-bottom: 40px;
}

.support {

    background: var(--dark-grey);
    border-radius: 20px;

}

.supporticon {

    min-width: 34px;
    height: 34px;
    border-radius: 20px;
    background-color: var(--dark_grey2);

}

.support_contact p {

    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 400;
}

.support_contact h1 {

    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 500;

}

.supporticon img {

    filter: invert(70%) sepia(66%) saturate(477%) hue-rotate(2deg) brightness(91%) contrast(85%);
    border-width: 2px;
    width: 20px;
    height: 20px;
}

.mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    height: 300px;

}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 300px;
    border-radius: 20px;
}

.gmap_iframe {

    height: 300px !important;
}

.contact_us .form-control {

    border-radius: 0;
    border: 1px solid var(--dark_grey2);
    background-color: var(--dark_grey2);
    padding: 10px 20px;

    transition: none;
    font-size: 16px;
    font-weight: 500;
    width: 100%;

}

.contact_us .form-control::placeholder {

    color: var(--grey);

}

.contact_us .btnsend {
    background-color: var(--yellow);
    color: var(--white);
    padding: 6px 12px;
    border: 1px solid var(--yellow);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;

}

.form-control:focus {
    box-shadow: unset;
    background-color: var(--white);
    color: var(--black);
    outline: none;

}

.form-control {
    resize: unset;

}

/*support_contact css end*/

/*subscribe_now css start*/

.subscribe_nowbg {

    background-image: url('@/assets/images/subscribebgimage.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    margin-top: 40px;
    border-radius: 10px;
    max-height: 400px;

}

.subscribe_nowbg h1 {
    font-size: 25px;
    color: var(--yellow);

}

.subscribe_nowbg p {

    color: var(--white);

}

.subscribe_nowbg .btnsend {

    background-color: var(--yellow);
    color: var(--white);
    padding: 7px 13px;
    text-decoration: none;
    border: 1px solid var(--yellow);
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;

}

.subscribe_nowbg .form-control {

    border-radius: 0;
    border: 1px solid var(--yellow);
    background-color: var(--dark_grey2);
    padding: 6px 7px;
    color:var(--white);
    transition: none;
    font-size: 14px;
    font-weight: 500;
    min-width: 100%;


}
/* .subscribe_nowbg  .subscribe_form.form-control::placeholder{
    color: var(--white) !important;
    font-weight: 500;
    font-size:14px
} */

.manimage {

    position: relative;
    top: -140px;

}

/* roadmap css start */
.roadmap_box:before {
    position: absolute;
    content: '';
    top: 264px;
    left: 46px;
    right: 0;
    margin: auto;
    background-image: url(../assets/images/home/roadmap/before1.png);
    background-repeat: no-repeat;
    height: 1500px;
    width: 740px;
}

.roadmap_box .heading_roadmap p {
    color: var(--text-color);
    font-size: 15px;
}

.roadmap_box .roadmap_height {
    color: var(--white);
    min-height: 207px;
}

.rodmap_july {
    transform: translate(-70px, 0);
}

.roadmap_height .content_roadmap h3 {
    color: var(--yellow);
    font-size: 19px;
}

.roadmap_height .content_roadmap p {
    color: var(--text-color);
    font-size: 15px;
    word-break: break-all;
}

.left-transform {
    transform: translate(108px);
}

.text-transform1 {
    transform: translateY(77px);
}

/* ================================
            MEDIA QUERY
 ================================ */
@media all and (min-width:1200px) and (max-width:1399px) {
    .banner_haeding h1 {
        color: var(--white);
        font-size: 40px;
    }

    .glass_effect {
        min-height: 310px;
    }

    .support_contact br {

        display: none;
    }

    .support_contact h1 {

        font-size: 17px
    }

    .support_contact p {

        font-size: 16px;

    }

    .subscribe_nowbg br {

        display: none;

    }

    .manimage {

        position: relative;
        top: -140px;

    }

    .roadmap_box:before {
        display: block;
    }
    .rodmap_july{
        transform: translate(-100px, 0) !important;
    }
    .roadmap_public{
        transform: translate(69px, 0px) !important;

    }
    /* .roadmap_height{
        transform: translate(69px, -18px) !important;
    } */

    /* .roadmap_box:before {
        height: 1219px;
        width: 583px;
        background-size: contain;
    } */

    .roadmap_box .roadmap_height {
        margin: auto;
        padding-left: 0 !important;
    }

    .rodmap_july {
        transform: translate(0, 0);
    }

}

@media all and (min-width:992px) and (max-width:1199px) {
    .glass_effect {
        min-height: 328px;
    }

    .banner_haeding h1 {
        color: var(--white);
        font-size: 35px;
        line-height: 45px;
    }

    .support_contact br {

        display: none;
    }

    .support_contact h1 {

        font-size: 14px
    }

    .subscribe_nowbg {

        max-height: 320px;

    }

    .subscribe_nowbg br {

        display: none;

    }

    .manimage {

        top: -108px;

    }

    .roadmap_box:before {
        display: none;
    }

    .roadmap_box .roadmap_height {
        margin: auto;
        padding-left: 0 !important;
    }

    .rodmap_july {
        transform: translate(0, 0);
    }
}

@media all and (min-width:768px) and (max-width:991px) {
    .glass_effect {
        min-height: 310px;
    }
    .choose_us_video video{
        display: none;
    }
    .choose_us_content{
        position:unset;
        top:0 !important;
        height: unset;
    }
    .banner_haeding h1 {
        color: var(--white);
        font-size: 30px;
        line-height: 40px;
    }

    .support_contact br {

        display: none;
    }

    .support_contact h1 {

        font-size: 14px
    }

    .subscribe_nowbg br {

        display: none;

    }

    .subscribe_nowbg {

        max-height: 235px;

    }

    .manimage {

        top: -79px;

    }

    .subscribe_nowbg h1 {

        font-size: 27px
    }

    .subscribe_nowbg .btnsend {

        padding: 9px 13px;
        font-size: 12px;
        white-space: nowrap;
    }

    .line-none::before {
        content: unset;
    }

    .roadmap_box:before {
        display: none;
    }

    .roadmap_box .roadmap_height {
        margin: auto;
        padding-left: 0 !important;
    }

    .roadmap_height .content_roadmap p br {
        display: none;
    }

    .left-transform {
        transform: translate(59px);
    }

    .rodmap_july {
        transform: translate(0, 0);
    }
  
}

@media all and (min-width:320px) and (max-width:767px) {
    .banner_content {
        height: 50vh;
    }

    .banner_haeding h1 {
        font-size: 20px;
        line-height: 30px;
    }

    .banner_haeding p {
        word-break: break-all;
    }

    .banner_video video {
        height: 80vh;
    }

    .home_banner {
        height: 80vh;
    }

    /* choose card media query */

    .choose-card {
        min-height: 308px;
    }

    .choose-info h2 {
        color: var(--yellow);
        font-size: 18px;
    }

    .choose-info p {
        text-align: justify;
    }

    .left-info {
        transform: translate(0px, 0px);
    }

    .our-bot-text,
    .download-text {
        text-align: center;
    }

    .about_info p {
        word-break: break-all;
    }

    .about_us .about_info h3 {
        font-size: 23px;
        text-align: center;
    }

    .about_us .about_info p {
        font-size: 16px;
    }

    .feature_box_content h3 {
        font-size: 16px;
    }

    .glass_effect {
        min-height: 325px;
    }

    .support_contact br {

        display: none;
    }

    #home {

        padding: 5px;

    }

    .support_contactbg {

        background: var(--contact-bg);
        border-radius: 20px;

        color: var(--white);
        padding: 20px;
    }

    .subscribe_nowbg {

        max-height: 800px;

    }

    .manimage {

        top: -8px;

    }

    .support {

        background: var(--dark-grey);
        border-radius: 20px;

    }

    .support_contact p {

        font-size: 16px;
        font-family: 'Montserrat';
        font-weight: 400;
    }

    .support_contact h1 {

        font-size: 14px;
        font-family: 'Montserrat';
        font-weight: 500;

    }

    .mapouter {

        height: 280px;

    }

    .gmap_canvas {

        height: 280px;

    }

    .gmap_iframe {

        height: 280px !important;
    }

    .subscribe_nowbg .btnsend {

        padding: 9px 13px;
        font-size: 12px;
        white-space: nowrap;
    }

    .subscribe_nowbg br {

        display: none;
    }

    .wallet-box-inner::before {
        content: unset;
    }

    .roadmap_box:before {
        display: none;
    }

    .roadmap_box .roadmap_height {
        width: 100% !important;
        padding: 0 10px !important;
        margin: 20px 0 !important;
    }

    .roadmap_height .content_roadmap p {
        font-size: 14px;
    }

    .roadmap_height .content_roadmap h3 {
        font-size: 18px;
    }

    .image_text {
        flex-direction: column;
    }

    .image_text img {
        margin-right: 0 !important;
        margin-bottom: 5px;
    }

    .content_roadmap {
        text-align: center;
    }

    .roadmap_height .content_roadmap p br {
        display: none;
    }

    .left-transform {
        transform: unset;
    }

    .text-transform1 {
        transform: unset;
    }

    .rodmap_july {
        transform: translate(0, 0);
    }
    .choose_us_content{
        position:unset;
        top:0 !important;
        height: unset;
    }
    .choose_us_video video{
        display: none;
    }
    .our-bot p {
    font-size: 16px;
    text-align: start;
}
}

@media all and (min-width:320px) and (max-width:576px) {

    .banner_haeding h1 br,
    .banner_haeding p br {
        display: none;
    }

}
</style>
